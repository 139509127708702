@font-face {
  font-family: "iconfont"; /* Project id 2399838 */
  src: url('../fonts/iconfont.eot?t=1653876274287'); /* IE9 */
  src: url('../fonts/iconfont.eot?t=1653876274287#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/iconfont.woff2?t=1653876274287') format('woff2'),
       url('../fonts/iconfont.woff?t=1653876274287') format('woff'),
       url('../fonts/iconfont.ttf?t=1653876274287') format('truetype'),
       /* url('iconfont.svg?t=1653876274287#iconfont') format('svg'); */
       url('../fonts/iconfont.svg?t=1653876274287#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconinstagram:before {
  content: "\e718";
}

.icona-maijia-gerenzhongxin1818:before {
  content: "\e6f0";
}

.iconworld-flag_-BLR--Belarus:before {
  content: "\e802";
}

.iconworld-flag_-BEL--Belgium:before {
  content: "\e803";
}

.iconworld-flag_-BEN--Benin:before {
  content: "\e804";
}

.iconworld-flag_-BLZ--Belize:before {
  content: "\e805";
}

.iconworld-flag_-BMU--Bermuda:before {
  content: "\e806";
}

.iconworld-flag_-BTN--Bhutan:before {
  content: "\e807";
}

.iconworld-flag_-BWA--Botswana:before {
  content: "\e808";
}

.iconworld-flag_-BVT--BouvetIsland:before {
  content: "\e809";
}

.iconworld-flag_-BIH--BosniaandHerzegovina:before {
  content: "\e80a";
}

.iconworld-flag_-BOL--Bolivia:before {
  content: "\e80b";
}

.iconworld-flag_-BRA--Brazil:before {
  content: "\e80c";
}

.iconworld-flag_-VGB--BritishVirginIslands:before {
  content: "\e80d";
}

.iconworld-flag_-IOT--BritishIndianOceanTerritory:before {
  content: "\e80e";
}

.iconworld-flag_-BFA--BurkinaFaso:before {
  content: "\e80f";
}

.iconworld-flag_-BGR--Bulgaria:before {
  content: "\e810";
}

.iconworld-flag_-BRN--BruneiDarussalam:before {
  content: "\e811";
}

.iconworld-flag_-BDI--Burundi:before {
  content: "\e812";
}

.iconworld-flag_-CMR--Cameroon:before {
  content: "\e813";
}

.iconworld-flag_-KHM--Cambodia:before {
  content: "\e814";
}

.iconworld-flag_-CAN--Canada:before {
  content: "\e815";
}

.iconworld-flag_-BES--CaribbeanNetherlands:before {
  content: "\e816";
}

.iconworld-flag_-CPV--CapeVerde:before {
  content: "\e817";
}

.iconworld-flag_-CHL--Chile:before {
  content: "\e818";
}

.iconworld-flag_-TCD--Chad:before {
  content: "\e819";
}

.iconworld-flag_-CAF--CentralAfricanRepublic:before {
  content: "\e81a";
}

.iconworld-flag_-CHN--China:before {
  content: "\e81b";
}

.iconworld-flag_-CYM--CaymanIslands:before {
  content: "\e81c";
}

.iconworld-flag_-CXR--ChristmasIsland:before {
  content: "\e81d";
}

.iconworld-flag_-CCK--CocosKeelingIslands:before {
  content: "\e81e";
}

.iconworld-flag_-COL--Colombia:before {
  content: "\e81f";
}

.iconworld-flag_-COG--CongoBrazzaville:before {
  content: "\e820";
}

.iconworld-flag_-COD--CongoKinshasa:before {
  content: "\e821";
}

.iconworld-flag_-COM--Comoros:before {
  content: "\e822";
}

.iconworld-flag_-COK--CookIslands:before {
  content: "\e823";
}

.iconworld-flag_-CIV--CtedIvoire:before {
  content: "\e824";
}

.iconworld-flag_-CRI--CostaRica:before {
  content: "\e825";
}

.iconworld-flag_-HRV--Croatia:before {
  content: "\e826";
}

.iconworld-flag_-CUB--Cuba:before {
  content: "\e827";
}

.iconworld-flag_-CUW--Curaao:before {
  content: "\e828";
}

.iconworld-flag_-CYP--Cyprus:before {
  content: "\e829";
}

.iconworld-flag_-CZE--CzechRepublic:before {
  content: "\e82a";
}

.iconworld-flag_-DNK--Denmark:before {
  content: "\e82b";
}

.iconworld-flag_-DJI--Djibouti:before {
  content: "\e82c";
}

.iconworld-flag_-DMA--Dominica:before {
  content: "\e82d";
}

.iconworld-flag_-DOM--DominicanRepublic:before {
  content: "\e82e";
}

.iconworld-flag_-ECU--Ecuador:before {
  content: "\e82f";
}

.iconworld-flag_-EGY--Egypt:before {
  content: "\e830";
}

.iconworld-flag_-SLV--ElSalvador:before {
  content: "\e831";
}

.iconworld-flag_-GB-ENG--England:before {
  content: "\e832";
}

.iconworld-flag_-GNQ--EquatorialGuinea:before {
  content: "\e833";
}

.iconworld-flag_-EST--Estonia:before {
  content: "\e834";
}

.iconworld-flag_-ETH--Ethiopia:before {
  content: "\e835";
}

.iconworld-flag_-ERI--Eritrea:before {
  content: "\e836";
}

.iconworld-flag_-FRO--FaroeIslands:before {
  content: "\e837";
}

.iconworld-flag_-FLK--FalklandIslandsMalvinas:before {
  content: "\e838";
}

.iconworld-flag_-FRA--France:before {
  content: "\e839";
}

.iconworld-flag_-FIN--Finland:before {
  content: "\e83a";
}

.iconworld-flag_-FJI--Fiji:before {
  content: "\e83b";
}

.iconworld-flag_-GUF--FrenchGuiana:before {
  content: "\e83c";
}

.iconworld-flag_-GMB--Gambia:before {
  content: "\e83d";
}

.iconworld-flag_-GAB--Gabon:before {
  content: "\e83e";
}

.iconworld-flag_-PYF--FrenchPolynesia:before {
  content: "\e83f";
}

.iconworld-flag_-ATF--FrenchSouthernTerritories:before {
  content: "\e840";
}

.iconworld-flag_-GEO--Georgia:before {
  content: "\e841";
}

.iconworld-flag_-GRL--Greenland:before {
  content: "\e842";
}

.iconworld-flag_-GHA--Ghana:before {
  content: "\e843";
}

.iconworld-flag_-GRC--Greece:before {
  content: "\e844";
}

.iconworld-flag_-DEU--Germany:before {
  content: "\e845";
}

.iconworld-flag_-GIB--Gibraltar:before {
  content: "\e846";
}

.iconworld-flag_-GRD--Grenada:before {
  content: "\e847";
}

.iconworld-flag_-GUM--Guam:before {
  content: "\e848";
}

.iconworld-flag_-GTM--Guatemala:before {
  content: "\e849";
}

.iconworld-flag_-GIN--Guinea:before {
  content: "\e84a";
}

.iconworld-flag_-GNB--GuineaBissau:before {
  content: "\e84b";
}

.iconworld-flag_-GUY--Guyana:before {
  content: "\e84c";
}

.iconworld-flag_-GGY--Guernsey:before {
  content: "\e84d";
}

.iconworld-flag_-HTI--Haiti:before {
  content: "\e84e";
}

.iconworld-flag_-VAT--HolySeeVaticanCityState:before {
  content: "\e84f";
}

.iconworld-flag_-HMD--HeardandMcdonaldIslands:before {
  content: "\e850";
}

.iconworld-flag_-HND--Honduras:before {
  content: "\e851";
}

.iconworld-flag_-HKG--HongKongSARChina:before {
  content: "\e852";
}

.iconworld-flag_-HUN--Hungary:before {
  content: "\e853";
}

.iconworld-flag_-IND--India:before {
  content: "\e854";
}

.iconworld-flag_-ISL--Iceland:before {
  content: "\e855";
}

.iconworld-flag_-IDN--Indonesia:before {
  content: "\e856";
}

.iconworld-flag_-IRQ--Iraq:before {
  content: "\e857";
}

.iconworld-flag_-IRN--IranIslamicRepublicof:before {
  content: "\e858";
}

.iconworld-flag_-IRL--Ireland:before {
  content: "\e859";
}

.iconworld-flag_-IMN--IsleofMan:before {
  content: "\e85a";
}

.iconworld-flag_-ISR--Israel:before {
  content: "\e85b";
}

.iconworld-flag_-ITA--Italy:before {
  content: "\e85c";
}

.iconworld-flag_-JAM--Jamaica:before {
  content: "\e85d";
}

.iconworld-flag_-JPN--Japan:before {
  content: "\e85e";
}

.iconworld-flag_-JEY--Jersey:before {
  content: "\e85f";
}

.iconworld-flag_-JOR--Jordan:before {
  content: "\e860";
}

.iconworld-flag_-KEN--Kenya:before {
  content: "\e861";
}

.iconworld-flag_-PRK--KoreaNorth:before {
  content: "\e862";
}

.iconworld-flag_-KOR--KoreaSouth:before {
  content: "\e863";
}

.iconworld-flag_-KIR--Kiribati:before {
  content: "\e864";
}

.iconworld-flag_-LAO--LaosPDR:before {
  content: "\e865";
}

.iconworld-flag_-KWT--Kuwait:before {
  content: "\e866";
}

.iconworld-flag_-KGZ--Kyrgyzstan:before {
  content: "\e867";
}

.iconworld-flag_-XKX--Kosovo:before {
  content: "\e868";
}

.iconworld-flag_-KAZ--Kazakhstan:before {
  content: "\e869";
}

.iconworld-flag_-LVA--Latvia:before {
  content: "\e86a";
}

.iconworld-flag_-LBN--Lebanon:before {
  content: "\e86b";
}

.iconworld-flag_-LBR--Liberia:before {
  content: "\e86c";
}

.iconworld-flag_-LSO--Lesotho:before {
  content: "\e86d";
}

.iconworld-flag_-LBY--Libya:before {
  content: "\e86e";
}

.iconworld-flag_-LUX--Luxembourg:before {
  content: "\e86f";
}

.iconworld-flag_-LTU--Lithuania:before {
  content: "\e870";
}

.iconworld-flag_-LIE--Liechtenstein:before {
  content: "\e871";
}

.iconworld-flag_-MAC--MacaoSARChina:before {
  content: "\e872";
}

.iconworld-flag_-MDG--Madagascar:before {
  content: "\e873";
}

.iconworld-flag_-MKD--MacedoniaRepublicof:before {
  content: "\e874";
}

.iconworld-flag_-MLI--Mali:before {
  content: "\e875";
}

.iconworld-flag_-MDV--Maldives:before {
  content: "\e876";
}

.iconworld-flag_-MWI--Malawi:before {
  content: "\e877";
}

.iconworld-flag_-MYS--Malaysia:before {
  content: "\e878";
}

.iconworld-flag_-MHL--MarshallIslands:before {
  content: "\e879";
}

.iconworld-flag_-MLT--Malta:before {
  content: "\e87a";
}

.iconworld-flag_-MRT--Mauritania:before {
  content: "\e87b";
}

.iconworld-flag_-MUS--Mauritius:before {
  content: "\e87c";
}

.iconworld-flag_-MTQ--Martinique:before {
  content: "\e87d";
}

.iconworld-flag_-MYT--Mayotte:before {
  content: "\e87e";
}

.iconworld-flag_-MEX--Mexico:before {
  content: "\e87f";
}

.iconworld-flag_-MCO--Monaco:before {
  content: "\e880";
}

.iconworld-flag_-MDA--Moldova:before {
  content: "\e881";
}

.iconworld-flag_-FSM--MicronesiaFederatedStatesof:before {
  content: "\e882";
}

.iconworld-flag_-MNG--Mongolia:before {
  content: "\e883";
}

.iconworld-flag_-MNE--Montenegro:before {
  content: "\e884";
}

.iconworld-flag_-MAR--Morocco:before {
  content: "\e885";
}

.iconworld-flag_-MOZ--Mozambique:before {
  content: "\e886";
}

.iconworld-flag_-MMR--Myanmar:before {
  content: "\e887";
}

.iconworld-flag_-MSR--Montserrat:before {
  content: "\e888";
}

.iconworld-flag_-NAM--Namibia:before {
  content: "\e889";
}

.iconworld-flag_-NLD--Netherlands:before {
  content: "\e88a";
}

.iconworld-flag_-NRU--Nauru:before {
  content: "\e88b";
}

.iconworld-flag_-NPL--Nepal:before {
  content: "\e88c";
}

.iconworld-flag_-ANT--NetherlandsAntilles:before {
  content: "\e88d";
}

.iconworld-flag_-NCL--NewCaledonia:before {
  content: "\e88e";
}

.iconworld-flag_-NZL--NewZealand:before {
  content: "\e88f";
}

.iconworld-flag_-NIC--Nicaragua:before {
  content: "\e890";
}

.iconworld-flag_-NGA--Nigeria:before {
  content: "\e891";
}

.iconworld-flag_-NER--Niger:before {
  content: "\e892";
}

.iconworld-flag_-NIU--Niue:before {
  content: "\e893";
}

.iconworld-flag_-NFK--NorfolkIsland:before {
  content: "\e894";
}

.iconworld-flag_-MNP--NorthernMarianaIslands:before {
  content: "\e895";
}

.iconworld-flag_-NOR--Norway:before {
  content: "\e896";
}

.iconworld-flag_-GB-NIR--NorthernIreland:before {
  content: "\e897";
}

.iconworld-flag_-OMN--Oman:before {
  content: "\e898";
}

.iconworld-flag_-PSE--PalestinianTerritory:before {
  content: "\e899";
}

.iconworld-flag_-PAK--Pakistan:before {
  content: "\e89a";
}

.iconworld-flag_-PLW--Palau:before {
  content: "\e89b";
}

.iconworld-flag_-PAN--Panama:before {
  content: "\e89c";
}

.iconworld-flag_-PNG--PapuaNewGuinea:before {
  content: "\e89d";
}

.iconworld-flag_-PRY--Paraguay:before {
  content: "\e89e";
}

.iconworld-flag_-PHL--Philippines:before {
  content: "\e89f";
}

.iconworld-flag_-PER--Peru:before {
  content: "\e8a0";
}

.iconworld-flag_-POL--Poland:before {
  content: "\e8a1";
}

.iconworld-flag_-PRT--Portugal:before {
  content: "\e8a2";
}

.iconworld-flag_-PRI--PuertoRico:before {
  content: "\e8a3";
}

.iconworld-flag_-QAT--Qatar:before {
  content: "\e8a4";
}

.iconworld-flag_-REU--Runion:before {
  content: "\e8a5";
}

.iconworld-flag_-ROU--Romania:before {
  content: "\e8a6";
}

.iconworld-flag_-RUS--RussianFederation:before {
  content: "\e8a7";
}

.iconworld-flag_-RWA--Rwanda:before {
  content: "\e8a8";
}

.iconworld-flag_-PCN--Pitcairn:before {
  content: "\e8a9";
}

.iconworld-flag_-BLM--SaintBarthlemy:before {
  content: "\e8aa";
}

.iconworld-flag_-LCA--SaintLucia:before {
  content: "\e8ab";
}

.iconworld-flag_-MAF--SaintMartin:before {
  content: "\e8ac";
}

.iconworld-flag_-KNA--SaintKittsandNevis:before {
  content: "\e8ad";
}

.iconworld-flag_-SHN--SaintHelena:before {
  content: "\e8ae";
}

.iconworld-flag_-VCT--SaintVincentandGrenadines:before {
  content: "\e8af";
}

.iconworld-flag_-SPM--SaintPierreandMiquelon:before {
  content: "\e8b0";
}

.iconworld-flag_-STP--SaoTomeandPrincipe:before {
  content: "\e8b1";
}

.iconworld-flag_-SMR--SanMarino:before {
  content: "\e8b2";
}

.iconworld-flag_-WSM--Samoa:before {
  content: "\e8b3";
}

.iconworld-flag_-GB-SCT--Scotland:before {
  content: "\e8b4";
}

.iconworld-flag_-SAU--SaudiArabia:before {
  content: "\e8b5";
}

.iconworld-flag_-SEN--Senegal:before {
  content: "\e8b6";
}

.iconworld-flag_-SYC--Seychelles:before {
  content: "\e8b7";
}

.iconworld-flag_-SLE--SierraLeone:before {
  content: "\e8b8";
}

.iconworld-flag_-SRB--Serbia:before {
  content: "\e8b9";
}

.iconworld-flag_-SGP--Singapore:before {
  content: "\e8ba";
}

.iconworld-flag_-SXM--SintMaarten:before {
  content: "\e8bb";
}

.iconworld-flag_-SVK--Slovakia:before {
  content: "\e8bc";
}

.iconworld-flag_-SVN--Slovenia:before {
  content: "\e8bd";
}

.iconworld-flag_-SLB--SolomonIslands:before {
  content: "\e8be";
}

.iconworld-flag_-SOM--Somalia:before {
  content: "\e8bf";
}

.iconworld-flag_-ZAF--SouthAfrica:before {
  content: "\e8c0";
}

.iconworld-flag_-SSD--SouthSudan:before {
  content: "\e8c1";
}

.iconworld-flag_-SGS--SouthGeorgiaandtheSouthSa:before {
  content: "\e8c2";
}

.iconworld-flag_-ESP--Spain:before {
  content: "\e8c3";
}

.iconworld-flag_-LKA--SriLanka:before {
  content: "\e8c4";
}

.iconworld-flag_-SDN--Sudan:before {
  content: "\e8c5";
}

.iconworld-flag_-SUR--Suriname:before {
  content: "\e8c6";
}

.iconworld-flag_-SWZ--Swaziland:before {
  content: "\e8c7";
}

.iconworld-flag_-SYR--SyrianArabRepublicSyria:before {
  content: "\e8c8";
}

.iconworld-flag_-SWE--Sweden:before {
  content: "\e8c9";
}

.iconworld-flag_-SJM--SvalbardandJanMayenIslands:before {
  content: "\e8ca";
}

.iconworld-flag_-CHE--Switzerland:before {
  content: "\e8cb";
}

.iconworld-flag_-TWN--TaiwanRepublicofChina:before {
  content: "\e8cc";
}

.iconworld-flag_-UMI--USMinorOutlyingIslands:before {
  content: "\e8cd";
}

.iconworld-flag_-WLF--WallisandFutunaIslands:before {
  content: "\e8ce";
}

.iconworld-flag_-GBR--UnitedKingdom:before {
  content: "\e8cf";
}

.iconworld-flag_-URY--Uruguay:before {
  content: "\e8d0";
}

.iconworld-flag_-TTO--TrinidadandTobago:before {
  content: "\e8d1";
}

.iconworld-flag_-UKR--Ukraine:before {
  content: "\e8d2";
}

.iconworld-flag_-TZA--TanzaniaUnitedRepublicof:before {
  content: "\e8d3";
}

.iconworld-flag_-TLS--Timor-Leste:before {
  content: "\e8d4";
}

.iconworld-flag_-TON--Tonga:before {
  content: "\e8d5";
}

.iconworld-flag_-ARE--UnitedArabEmirates:before {
  content: "\e8d6";
}

.iconworld-flag_-TUN--Tunisia:before {
  content: "\e8d7";
}

.iconworld-flag_-VNM--VietNam:before {
  content: "\e8d8";
}

.iconworld-flag_-TGO--Togo:before {
  content: "\e8d9";
}

.iconworld-flag_-YEM--Yemen:before {
  content: "\e8da";
}

.iconworld-flag_-THA--Thailand:before {
  content: "\e8db";
}

.iconworld-flag_-TUR--Turkey:before {
  content: "\e8dc";
}

.iconworld-flag_-ZMB--Zambia:before {
  content: "\e8dd";
}

.iconworld-flag_-TKL--Tokelau:before {
  content: "\e8de";
}

.iconworld-flag_---NorthernCyprus:before {
  content: "\e8df";
}

.iconworld-flag_---World:before {
  content: "\e8e0";
}

.iconworld-flag_-ZWE--Zimbabwe:before {
  content: "\e8e1";
}

.iconworld-flag_-ESH--WesternSaharaSahrawiArab:before {
  content: "\e8e2";
}

.iconworld-flag_---ASEAN:before {
  content: "\e8e3";
}

.iconworld-flag_-VEN--VenezuelaBolivarianRepublic:before {
  content: "\e8e4";
}

.iconworld-flag_-TCA--TurksandCaicosIslands:before {
  content: "\e8e5";
}

.iconworld-flag_-TJK--Tajikistan:before {
  content: "\e8e6";
}

.iconworld-flag_-VUT--Vanuatu:before {
  content: "\e8e7";
}

.iconworld-flag_-UZB--Uzbekistan:before {
  content: "\e8e8";
}

.iconworld-flag_---Transnistria:before {
  content: "\e8e9";
}

.iconworld-flag_-UGA--Uganda:before {
  content: "\e8ea";
}

.iconworld-flag_-TKM--Turkmenistan:before {
  content: "\e8eb";
}

.iconworld-flag_---EuropeUnion:before {
  content: "\e8ec";
}

.iconworld-flag_-TUV--Tuvalu:before {
  content: "\e8ed";
}

.iconworld-flag_-ALA--AlandIslands:before {
  content: "\e7ee";
}

.iconworld-flag_-VIR--VirginIslandsUS:before {
  content: "\e8ee";
}

.iconworld-flag_-AFG--Afghanistan:before {
  content: "\e7ef";
}

.iconworld-flag_---UnitedNation:before {
  content: "\e8ef";
}

.iconworld-flag_-ALB--Albania:before {
  content: "\e7f0";
}

.iconworld-flag_-GB-WLS--Wales:before {
  content: "\e8f0";
}

.iconworld-flag_-DZA--Algeria:before {
  content: "\e7f1";
}

.iconworld-flag_-USA--UnitedStatesofAmerica:before {
  content: "\e8f1";
}

.iconworld-flag_-ASM--AmericanSamoa:before {
  content: "\e7f2";
}

.iconworld-flag_-TZA--TanzaniaUnitedRepublicof1:before {
  content: "\e8f2";
}

.iconworld-flag_-AND--Andorra:before {
  content: "\e7f3";
}

.iconworld-flag_-THA--Thailand1:before {
  content: "\e8f3";
}

.iconworld-flag_-AGO--Angola:before {
  content: "\e7f4";
}

.iconworld-flag_-TJK--Tajikistan1:before {
  content: "\e8f4";
}

.iconworld-flag_-AIA--Anguilla:before {
  content: "\e7f5";
}

.iconworld-flag_-TLS--Timor-Leste1:before {
  content: "\e8f5";
}

.iconworld-flag_-ATA--Antarctica:before {
  content: "\e7f6";
}

.iconworld-flag_-TGO--Togo1:before {
  content: "\e8f6";
}

.iconworld-flag_-ABW--Aruba:before {
  content: "\e7f7";
}

.iconworld-flag_-TKL--Tokelau1:before {
  content: "\e8f7";
}

.iconworld-flag_-ARM--Armenia:before {
  content: "\e7f8";
}

.iconworld-flag_-TON--Tonga1:before {
  content: "\e8f8";
}

.iconworld-flag_-ATG--AntiguaandBarbuda:before {
  content: "\e7f9";
}

.iconworld-flag_-ARG--Argentina:before {
  content: "\e7fa";
}

.iconworld-flag_-AZE--Azerbaijan:before {
  content: "\e7fb";
}

.iconworld-flag_-BHS--Bahamas:before {
  content: "\e7fc";
}

.iconworld-flag_-BGD--Bangladesh:before {
  content: "\e7fd";
}

.iconworld-flag_-AUT--Austria:before {
  content: "\e7fe";
}

.iconworld-flag_-BHR--Bahrain:before {
  content: "\e7ff";
}

.iconworld-flag_-AUS--Australia:before {
  content: "\e800";
}

.iconworld-flag_-BRB--Barbados:before {
  content: "\e801";
}

.iconarrow-left-bold:before {
  content: "\e685";
}

.iconarrow-up-bold:before {
  content: "\e686";
}

.iconarrow-down-bold:before {
  content: "\e687";
}

.iconarrow-right-bold:before {
  content: "\e688";
}

.iconyidongduan:before {
  content: "\e68a";
}

.iconPCduan:before {
  content: "\e68b";
}

.iconWhatsApp:before {
  content: "\e62d";
}

.iconbofang:before {
  content: "\e62b";
}

.iconfenyezuojiantou:before {
  content: "\e625";
}

.iconfenyeyoujiantou:before {
  content: "\e628";
}

.iconbannerzuojiantou:before {
  content: "\e62a";
}

.iconbanneryoujiantou:before {
  content: "\e62c";
}

.iconguanbi:before {
  content: "\e621";
}

.iconfacebook:before {
  content: "\e622";
}

.iconLinkedIn:before {
  content: "\e623";
}

.iconTwitter:before {
  content: "\e624";
}

.iconPinterest:before {
  content: "\e626";
}

.iconYouTube:before {
  content: "\e627";
}

.iconSearch:before {
  content: "\e629";
}

