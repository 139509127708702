@import "swiper/swiper-bundle.css";
@import "../fonts/iconfont.css";
@import "magnifier.js/magnifier.css";

[x-cloak] {
	opacity: 0 !important;
}

:root {
	--swiper-navigation-size: 1rem;
	--swiper-pagination-color: #fff;
	--swiper-theme-color: #fff;
}

.swiper-slide-thumb-active {
	border: 2px solid #000
}

.js-image-zoom__zoomed-image {
	width: 100%;
}
